//* External
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Accordion as BootstrapAccordion } from 'react-bootstrap';

//* App Custom
import { AccordionToggle } from './AccordionToggle/AccordionToggle';
import './accordion.scss';

const Accordion = ({
  data = [],
  header,
  type = 'normal',
  body,
  isSidebar = false,
  onEnter = () => {}
}) => {
  const [activeKey, setActiveKey] = useState(null);
  useEffect(() => {
    if (!isSidebar) setActiveKey(null), [data];
  }, []);

  const style = useMemo(() => {
    let header = 'accordion_header';
    let body = 'accordion_body';
    if (type === 'light') {
      header = 'accordion_header_light';
      body = 'accordion_header_body';
    }
    return { header, body };
  }, [type]);

  return (
    <BootstrapAccordion activeKey={activeKey}>
      {data.map((item, index) => (
        <div key={index}>
          <div
            className={`${style.header}${
              `${index}` === activeKey ? '_active' : ''
            }`}
          >
            <div
              className={`d-flex align-items-center justify-content-between gap-3 w-100 ${
                isSidebar ? 'cursor-pointer' : ''
              }`}
              onClick={() => {
                if (isSidebar) {
                  if (activeKey !== `${index}`) {
                    setActiveKey(`${index}`);
                  } else setActiveKey(null);
                }
              }}
            >
              {header(item)}
              <AccordionToggle
                item={item}
                index={index}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                onEnter={onEnter}
              />
            </div>
          </div>
          <BootstrapAccordion.Collapse eventKey={`${index}`}>
            <div className={style.body}>{body(item)}</div>
          </BootstrapAccordion.Collapse>
        </div>
      ))}
    </BootstrapAccordion>
  );
};

Accordion.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.func.isRequired,
  body: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['normal', 'light']),
  isSidebar: PropTypes.bool,
  onEnter: PropTypes.func
};

export default Accordion;
