//* External
import PropTypes from 'prop-types';
import { useMemo } from 'react';

//* App Custom
import { useLocalStorage } from 'hooks/index';

const RestrictedComponent = ({ permissions, children }) => {
  const { userData, userPermissions } = useLocalStorage();

  const isVisible = useMemo(() => {
    if (userData && permissions.length > 0) {
      return permissions.every((permission) =>
        userPermissions.includes(permission)
      );
    } else if (userData) return true;
    else return false;
  }, [userData, permissions]);

  return <>{isVisible && children}</>;
};

RestrictedComponent.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired
};

export default RestrictedComponent;
