//* External
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Button as BtnBootstrap, Spinner } from 'react-bootstrap';

//* App Custom
import { tnl } from 'i18n/i18n';
import { CustomIcon } from '../index';
import LinkButtonWrapper from './LinkButtonWrapper/LinkButtonWrapper';
import './button.scss';

const Button = ({
  type = 'button',
  variant = 'primary',
  border = 'full',
  title = '',
  text = '',
  iconName,
  disabled = false,
  fullWidth = false,
  isDoingRequest = false,
  fileToDownload,
  onClick = () => {}
}) => {
  const borderSelected = {
    full: 'btn_border_full',
    left: 'btn_border_left',
    right: 'btn_border_right',
    none: 'btn_border_none'
  };

  const props = {
    type,
    title: tnl(title),
    disabled: disabled || isDoingRequest,
    onClick
  };

  return (
    <LinkButtonWrapper link={fileToDownload} disabled={props.disabled}>
      <div
        title={props.title}
        className={!fullWidth ? 'btn_fit_container' : ''}
      >
        <BtnBootstrap
          {...props}
          className={`${fullWidth ? 'w-100' : 'w-auto'} ${
            borderSelected[border]
          } btn_${variant} px-4 py-2`}
        >
          {iconName && <CustomIcon name={iconName} />}
          {text && tnl(text)}
          {isDoingRequest && (
            <Spinner role="button_spinner" animation="border" size="sm" />
          )}
        </BtnBootstrap>
      </div>
    </LinkButtonWrapper>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'danger']),
  border: PropTypes.oneOf(['full', 'left', 'right', 'none']),
  title: PropTypes.string,
  text: PropTypes.string,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isDoingRequest: PropTypes.bool,
  fileToDownload: PropTypes.string,
  onClick: PropTypes.func
};

const MemoizeButton = memo(Button);
export default MemoizeButton;
