//* External
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Col } from 'react-bootstrap';

//* App Custom
import { tnl } from 'i18n/i18n';

const Counter = ({ registries, params }) => {
  const { totalCount } = registries;
  const counter = useMemo(() => {
    const adjustedPage = (params.page || 0) + 1;
    const initialCounter =
      adjustedPage === 1
        ? 1
        : Math.min(params.length * params?.page + 1, totalCount);
    const finalCounter = Math.min(adjustedPage * params.length, totalCount);
    if (totalCount <= params.length)
      return tnl('components.dataPaginated_few_registries', {
        totalCount
      });
    else
      return tnl('components.dataPaginated_many_registries', {
        initialCounter,
        finalCounter,
        totalCount
      });
  }, [totalCount, params]);

  return <Col>{counter}</Col>;
};

Counter.propTypes = {
  registries: PropTypes.exact({
    isSearching: PropTypes.bool,
    totalCount: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  params: PropTypes.object.isRequired
};

export default Counter;
