//* External
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

//* App Custom
import { InputSelect } from 'components/common';
import { tnl } from 'i18n/i18n';

const Pagination = ({
  registries,
  registriesPerPage = [],
  params,
  setParams = () => {}
}) => {
  const optionsDataPerView = registriesPerPage.map((option) => ({
    label: tnl('components.dataPaginated_select_registries', { option }),
    value: option
  }));

  return (
    <>
      <Col sm="auto">
        <InputSelect
          name="registries"
          placeholder="components.data_paginated_registries_per_view"
          options={optionsDataPerView}
          defaultValue={find(optionsDataPerView, { value: params.length })}
          onEventChange={(e) => {
            if (params.length !== e.value) {
              setParams((prevstate) => ({
                ...prevstate,
                length: e.value,
                page: 0
              }));
            }
          }}
        />
      </Col>
      <Col sm="auto">
        <ReactPaginate
          forcePage={params.page}
          onPageChange={(e) => {
            setParams((prevState) => ({
              ...prevState,
              page: e.selected
            }));
          }}
          pageCount={Math.ceil(registries.totalCount / params.length)}
          breakLabel="..."
          nextLabel=">>"
          previousLabel="<<"
          renderOnZeroPageCount={null}
          pageRangeDisplayed={0}
          className="dataPaginated_pagination"
        />
      </Col>
    </>
  );
};

Pagination.propTypes = {
  registries: PropTypes.exact({
    isSearching: PropTypes.bool,
    totalCount: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  registriesPerPage: PropTypes.arrayOf(PropTypes.number)
};

export default Pagination;
