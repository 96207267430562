import { CELLPHONE_PATTERN, PHONE_PATTERN, REGEX } from 'constants/index';
import { tnl } from 'i18n/i18n';

export const createOptions = (array = []) =>
  array.map((item) => ({ label: tnl(item), value: item }));

export const createSelectFilterOptions = (array = []) =>
  array.map(({ label, value }) => ({ label: tnl(label), value }));

export const obtainParams = (cleanData, mode) => {
  return Object.fromEntries(
    Object.entries(cleanData).map((entry) => {
      if (Array.isArray(entry[1])) {
        return [entry[0], entry[1].map((item) => item[mode]).join(',')];
      } else if (entry[1]?.label) {
        return [entry[0], entry[1][mode]];
      } else return [entry[0], entry[1]];
    })
  );
};

export {
  cleanArray,
  cleanArrayOfObjects,
  cleanObject,
  verifyData
} from './cleaners';

export { formatDate, formatDateForInput, formatDateWithOffset } from './moment';
export { downloadServerFile, sendRequest, uploadS3File } from './sendRequest';

export const formatSizeUnits = (bytes) => {
  const roundBytes = (input) => input.toFixed(2);
  if (bytes >= 1073741824) {
    bytes = roundBytes(bytes / 1073741824) + 'GB';
  } else if (bytes >= 1048576) {
    bytes = roundBytes(bytes / 1048576) + 'MB';
  } else if (bytes >= 1024) {
    bytes = roundBytes(bytes / 1024) + ' KB';
  } else if (bytes > 1) {
    bytes = roundBytes(bytes) + ' bytes';
  } else if (bytes == 1) {
    bytes = roundBytes(bytes) + ' byte';
  } else {
    bytes = '0 bytes';
  }
  return bytes;
};

export const phonePatternHandler = ({
  origin,
  value,
  lookAt,
  patient,
  watch = () => {},
  setError = () => {},
  clearErrors = () => {}
}) => {
  switch (origin) {
    case 'rules':
      const phone_type = watch(lookAt[0]);
      if (
        [phone_type, patient?.phones?.[0]?.phone_type].includes('CELLPHONE')
      ) {
        return CELLPHONE_PATTERN;
      } else return PHONE_PATTERN;
    case 'onEventChange':
      const phoneNumber = watch(lookAt[1]);
      const phonePattern = {
        CELLPHONE: CELLPHONE_PATTERN.pattern,
        PHONE: PHONE_PATTERN.pattern
      };
      const phoneRegex = {
        CELLPHONE: REGEX.cellphone,
        PHONE: REGEX.phone
      };
      if (!phoneRegex[value].test(phoneNumber)) {
        setError(lookAt[1], phonePattern[value]);
      } else clearErrors(lookAt[1]);
      break;
    default:
      break;
  }
};

export {
  privateRouteOptions,
  sidebarItemsRender,
  sidebarRequestInfoValidated
} from './navigationHandler';
