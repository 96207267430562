import { REGEX } from './regex';

export const INPUT_REQUIRED = {
  required: {
    value: true,
    message: 'form_errors.input_is_required'
  }
};

export const EMAIL_PATTERN = {
  pattern: {
    value: REGEX.email,
    message: 'form_errors.email_pattern'
  }
};

export const PASSWORD_PATTERN = {
  pattern: {
    value: REGEX.password,
    message: 'form_errors.password_pattern'
  }
};

export const PHONE_PATTERN = {
  pattern: {
    value: REGEX.phone,
    message: 'form_errors.phone_pattern'
  }
};

export const CELLPHONE_PATTERN = {
  pattern: {
    value: REGEX.cellphone,
    message: 'form_errors.cellphone_pattern'
  }
};

export const ONLY_NUMBERS_PATTERN = {
  pattern: {
    value: REGEX.only_numbers,
    message: 'form_errors.only_numbers_pattern'
  }
};
