//* External
import i18next from 'i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//* App Custom
import { ErrorBoundaryBack, ErrorBoundaryFront } from 'components/common/index';
import Routes from 'routes/Routes';
import './app.scss';

const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_WEB_SITE_KEY}
    >
      <ToastContainer position="top-right" autoClose={5000} pauseOnHover />
      <I18nextProvider i18n={i18next}>
        <ErrorBoundaryBack>
          <ErrorBoundaryFront>
            <Routes />
          </ErrorBoundaryFront>
        </ErrorBoundaryBack>
      </I18nextProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
