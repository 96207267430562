//* External
import PropTypes from 'prop-types';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

//* App Custom
import { CustomIcon } from 'components/common';

const AccordionToggle = ({ item, index, activeKey, setActiveKey, onEnter }) => {
  const onClick = useAccordionButton(`${index}`, () => {
    if (activeKey !== `${index}`) {
      setActiveKey(`${index}`);
      onEnter(item);
    } else setActiveKey(null);
  });

  return (
    <div onClick={onClick}>
      <CustomIcon
        name={`${index}` === activeKey ? 'chevronUp' : 'chevronDown'}
        className="cursor-pointer"
      />
    </div>
  );
};

AccordionToggle.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  activeKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  setActiveKey: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired
};

export { AccordionToggle };
