export const BackendOption = {
  APPLIED: 'success',
  APPLICATION_MODULE: 'success',
  AUTHORIZATION_CANCELLED_EXPIRED: 'warning',
  AUTHORIZATION_ERROR: 'fail',
  AUTHORIZATION_NUMBER_CHANGE_BY_INVENTORY: 'warning',
  CLOSED: 'fail',
  CREATING_TABLE: 'info',
  DISCARDED: 'fail',
  DISPENSED: 'success',
  DOES_NOT_APPLY: 'info',
  NOT_APPLIED: 'fail',
  ERROR: 'fail',
  ERROR_REPORT: 'fail',
  FINALIZED: 'success',
  FINALIZED_WITH_ERROR: 'fail',
  FINISHED_REPORT: 'success',
  IN_MANAGEMENT: 'info',
  INITIATED: 'info',
  MANAGED: 'success',
  MASSIVE_LOAD: 'info',
  MAKING_REPORT: 'info',
  MEDICAL_ORDER_ERROR: 'fail',
  OPEN: 'info',
  PATIENT_MODULE: 'success',
  PENDING: 'warning',
  PROCESING: 'warning',
  RECEIVED: 'success',
  REPROCESSING: 'warning',
  REPROGRAMMING: 'warning',
  RESOLVED: 'success',
  RETURNED: 'fail',
  SCHEDULED: 'success',
  SENDING_REPORT: 'info',
  VALIDATING: 'info',
  WORKING_ASYNC: 'info',
  WRITING_REPORT: 'info',

  ANULADA: 'fail',
  'IMPRESA APROBADA': 'success',
  'SIST. GENITOURINARIO': 'info',
  'ENFERMEDADES HUERFANAS': 'info',
  'PROGRAMA ARTRITIS': 'info',
  VACUNAS: 'info',
  'PROGRAMA ONCOLOGICO': 'info',
  'ENFERMEDAD RENAL': 'info',
  OFTALMOLOGIA: 'info',
  'SISTEMA NERVIOSO': 'info',
  'SIST. ENDOCRINO': 'info',
  'ENFERMEDADES AUTOINMUNES / ONCOLOGIA': 'info',
  'SUPLEMENTACION HIERRO': 'info',
  OSTEOPOROSIS: 'info',
  OTROS: 'info',
  'TERAPIA HORMONAL': 'info',
  'PROGRAMA ARTRITIS / PROGRAMA ONCOLOGICO': 'info',
  'PLAN CANGURO': 'info',
  TRASPLANTE: 'info',
  'PROGRAMA HEMOFILIA': 'info',
  'ENFERMEDADES AUTOINMUNES': 'info',
  'PROGRAMA TOXINA': 'info'
};
