//* External
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Navigate } from 'react-router-dom';

//* App Custom
import { ErrorBoundaryFront } from 'components/common';
import { ROUTES } from 'constants/index';
import { useLocalStorage } from 'hooks/index';

const PublicRoute = ({ children }) => {
  const { userData, recaptcha, setRecaptcha } = useLocalStorage();

  return (
    <>
      {Object.keys(userData).length > 0 ? (
        <Navigate to={ROUTES.HOME} />
      ) : (
        <ErrorBoundaryFront>
          <GoogleReCaptcha
            onVerify={(token) => {
              if (!recaptcha) setRecaptcha(token);
            }}
          />
          {children}
        </ErrorBoundaryFront>
      )}
    </>
  );
};

export default PublicRoute;
