//* External
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';

//* App Custom
import IconEB from 'assets/ErrorBoundaryFront.svg';
import { Button } from 'components/common';
import { tnl } from 'i18n/i18n';
import './errorBoundaryFront.scss';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const stackTrace = error.stack || '';
  const stackLines = stackTrace.split('\n');
  const firstStackLine = stackLines[1] || '';

  // Extract file and line information from the stack trace
  const matchResult = /\s*at\s.*\((.*):(\d+):(\d+)\)/.exec(firstStackLine);

  const fileName = matchResult
    ? matchResult[1]
        .substring(matchResult[1].lastIndexOf('/') + 1)
        .split('?')[0]
    : 'Unknown file';

  const lineNumber = matchResult ? matchResult[2] : 'Unknown line';

  useEffect(() => {
    console.log({ message: error.message, fileName, lineNumber });
  }, []);

  return (
    <div className="eb_container">
      <div>
        <Image
          className="eb_container_image"
          src={IconEB}
          alt="img_eb_front"
          fluid
        />
        <h4 className="fw-bold">
          {tnl('components.errorBoundary_front_title')}
        </h4>
      </div>
      <div>
        <Button text="btns.app_reload" onClick={() => resetErrorBoundary()} />
      </div>
    </div>
  );
};

const ErrorBoundaryFront = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
};

ErrorBoundaryFront.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundaryFront;
