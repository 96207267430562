//* External
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

//* App Custom
import Input from './Input/Input';
import InputCheckbox from './InputCheckbox/InputCheckbox';
import InputDropzone from './InputDropzone/InputDropzone';
import InputSelect from './InputSelect/InputSelect';
import InputTextArea from './InputTextArea/InputTextArea';

const InputHandler = ({
  type,
  name,
  label,
  placeholder,
  iconName,
  iconColor,
  disabled = false,
  isMulti = false,
  link,
  design,
  defaultValue,
  rules = {},
  options = [],
  extensions = [],
  control,
  onInputChange = () => {},
  onEventChange = () => {}
}) => {
  let valueByDefault = defaultValue;
  if (type === 'select') {
    if (Array.isArray(defaultValue)) {
      valueByDefault = defaultValue.map((item) => item.value).join(',');
    } else if (defaultValue) valueByDefault = defaultValue.value;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={true}
      defaultValue={valueByDefault}
      render={({ field: { onChange }, fieldState: { error } }) => {
        let InputDisplayed;
        switch (type) {
          case 'text':
          case 'number':
          case 'date':
          case 'datetime-local':
          case 'password':
            InputDisplayed = (
              <Input
                type={type}
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                iconName={iconName}
                iconColor={iconColor}
                disabled={disabled}
                design={design}
                required={rules?.required?.value || false}
                hookError={error}
                hookOnChange={onChange}
                onInputChange={onInputChange}
              />
            );
            break;
          case 'checkbox':
            InputDisplayed = (
              <InputCheckbox
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                required={rules?.required?.value || false}
                design={design}
                link={link}
                hookError={error}
                hookOnChange={onChange}
                onInputChange={onInputChange}
              />
            );
            break;
          case 'text-area':
            InputDisplayed = (
              <InputTextArea
                name={name}
                label={label}
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                required={rules?.required?.value || false}
                design={design}
                hookError={error}
                hookOnChange={onChange}
                onInputChange={onInputChange}
              />
            );
            break;
          case 'select':
            InputDisplayed = (
              <InputSelect
                name={name}
                label={label}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                defaultValue={defaultValue}
                iconName={iconName}
                iconColor={iconColor}
                disabled={disabled}
                required={rules?.required?.value || false}
                design={design}
                hookError={error}
                hookOnChange={onChange}
                onInputChange={onInputChange}
                onEventChange={onEventChange}
              />
            );
            break;
          case 'dropzone':
            InputDisplayed = (
              <InputDropzone
                name={name}
                label={label}
                extensions={extensions}
                disabled={disabled}
                required={rules?.required?.value || false}
                design={design}
                hookError={error}
                hookOnChange={onChange}
                onInputChange={onInputChange}
              />
            );
            break;
          default:
            return (InputDisplayed = <></>);
        }
        return InputDisplayed;
      }}
    />
  );
};

InputHandler.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'date',
    'datetime-local',
    'password',
    'checkbox',
    'text-area',
    'select',
    'dropzone'
  ]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  link: PropTypes.string,
  design: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  rules: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
      ])
    })
  ),
  extensions: PropTypes.arrayOf(PropTypes.string),
  control: PropTypes.any.isRequired,
  onInputChange: PropTypes.func,
  onEventChange: PropTypes.func
};

export default InputHandler;
