import { createOptions } from 'utils/index';

export const ICON_SIZES = {
  XS: 14,
  S: 16,
  MD: 24,
  LG: 32,
  XL: 50
};

export const PHONE_OPTIONS = createOptions(['CELLPHONE', 'PHONE']);

export const DATE_FORMATS = {
  DAY: 'DD/MM/YYYY',
  HOUR: 'h:mm A',
  FULL_DATE: 'DD/MM/YYYY hh:mm A'
};

export const FILE_EXTENSIONS = {
  csv: 'csv',
  jpg: 'jpg',
  pdf: 'pdf',
  png: 'png',
  rar: 'rar',
  txt: 'txt',
  xls: 'xls',
  xlsx: 'xlsx',
  zip: 'zip'
};

export const REMOVE_FILTER_SELECT = {
  label: 'components.input_select_remove_filter',
  value: null
};

export { default as APIS } from './apis';
export { CSS_COLORS } from './colors';
export { ICONS } from './icons';
export {
  CELLPHONE_PATTERN,
  EMAIL_PATTERN,
  INPUT_REQUIRED,
  ONLY_NUMBERS_PATTERN,
  PASSWORD_PATTERN,
  PHONE_PATTERN
} from './inputRules';
export { default as PERMISSIONS } from './permissions';
export { REGEX } from './regex';
export { default as ROUTES } from './routes';
