//* External
import { find } from 'lodash';

//* App Custom
import { INPUT_REQUIRED, PHONE_OPTIONS } from 'constants/index';
import { phonePatternHandler } from 'utils/index';

const generateInputKey = (type = '', uniqueValue) => `${type}_${uniqueValue}`;

export const phoneInputs = ({ inputIndex, inputData, otherFormOptions }) => [
  {
    key: generateInputKey('phone_type', inputData.id),
    type: 'select',
    name: `phones[${inputIndex}].phone_type`,
    label: 'labels.phone_type',
    placeholder: 'placeholders.phone_type',
    options: PHONE_OPTIONS,
    defaultValue: find(PHONE_OPTIONS, { value: inputData?.phone_type }),
    onEventChange: ({ value }) => {
      phonePatternHandler({
        origin: 'onEventChange',
        value,
        lookAt: [
          `phones[${inputIndex}].phone_type`,
          `phones[${inputIndex}].phone_number`
        ],
        watch: otherFormOptions.watch,
        setError: otherFormOptions.setError,
        clearErrors: otherFormOptions.clearErrors
      });
    },
    rules: INPUT_REQUIRED,
    design: 'col-12 col-md-6 pe-0'
  },
  {
    key: generateInputKey('phone_number', inputData.id),
    type: 'text',
    name: `phones[${inputIndex}].phone_number`,
    label: 'labels.phone_number',
    placeholder: 'placeholders.phone_number',
    defaultValue: inputData?.phone_number,
    rules: {
      ...INPUT_REQUIRED,
      ...phonePatternHandler({
        origin: 'rules',
        patient: { phones: [inputData] },
        lookAt: [
          `phones[${inputIndex}].phone_type`,
          `phones[${inputIndex}].phone_number`
        ],
        watch: otherFormOptions.watch
      })
    },
    design: 'col-12 col-md-6 ps-0'
  }
];
