export default {
  ROOT: '/',
  ERROR: '/404',

  //* Public Routes
  LOGIN: '/login',

  //* Private Routes
  PLATFORM: '/platform',
  HOME: '/platform/home',
  FILE_HISTORY: '/platform/file_history',
  PATIENT_LIST: '/platform/patient_list',
  PATIENT: '/platform/patient'
};
