//* External
import { omit } from 'lodash';
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

//* App Custom
import { tnl } from 'i18n/i18n';
import { cleanObject, obtainParams, verifyData } from 'utils/index';
import { Button, InputHandler } from '../index';
import './offCanvasForm.scss';

const Form = ({
  type,
  inputs = [],
  onSubmit = () => {},
  control,
  handleSubmit = () => {}
}) => {
  const { isDoingRequest } = useSelector((state) => state.commonState);

  return (
    <>
      {inputs.length > 0 && (
        <div className="searchRequest">
          <form onSubmit={handleSubmit(onSubmit)} className="row">
            {inputs
              .filter((input) => !input?.isHidden)
              .map((input) => (
                <InputHandler
                  key={input.name}
                  {...input}
                  label={type === 'offcanvas' ? input.label : ''}
                  control={control}
                  design={
                    type !== 'offcanvas'
                      ? 'col-12 col-md-6 col-lg-3'
                      : 'col-12 mb-3'
                  }
                />
              ))}
            <Button
              type="submit"
              variant="secondary"
              border="none"
              title={type !== 'offcanvas' ? 'btns.filter' : ''}
              text={type === 'offcanvas' ? 'btns.filter' : ''}
              iconName="magnifyingGlass"
              disabled={isDoingRequest}
              fullWidth={type === 'offcanvas'}
            />
          </form>
        </div>
      )}
    </>
  );
};

const OffCanvasForm = ({
  inputs = () => [],
  inputParams = {},
  defaultValues = {},
  onSubmit = () => {}
}) => {
  const [show, setShow] = useState(false);
  const { control, handleSubmit } = useForm();
  const [newDefaultValues, setNewDefaultValues] = useState(
    omit(defaultValues, ['length', 'page'])
  );

  const inputsToRender = inputs({
    ...inputParams,
    defaultValues: newDefaultValues
  });

  const formSubmit = (data) => {
    const cleanData = cleanObject(data);
    const paramsForVisual = obtainParams(cleanData, 'label');
    const paramsForRequest = obtainParams(cleanData, 'value');
    setNewDefaultValues(paramsForVisual);
    onSubmit(paramsForRequest);
  };

  return (
    <>
      {inputsToRender.length <= 3 ? (
        <div className="col-12 searchRequest">
          <Form
            type="simple"
            inputs={inputsToRender}
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={formSubmit}
          />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-start gap-3">
            <Button
              variant="secondary"
              border="none"
              text="labels.filters"
              iconName="filter"
              onClick={() => setShow(true)}
            />
            {Object.keys(newDefaultValues).length > 0 && (
              <div className="d-flex flex-wrap">
                {Object.entries(newDefaultValues).map((entry, index) => {
                  let value = entry[1];
                  if (typeof entry === 'string' && entry[1].includes(',')) {
                    const newValue = value.split(',').map((item) => tnl(item));
                    value = newValue;
                  } else value = tnl(entry[1]);
                  return (
                    <div
                      key={`filter_${index}`}
                      className="d-flex gap-1 offcanvas_applied_filter"
                    >
                      {verifyData([tnl(`labels.${entry[0]}`), value], ': ')}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <Offcanvas
            show={show}
            onHide={() => setShow(false)}
            placement="start"
            name="start"
          >
            <div className="p-3">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fw-bold offcanvas_title">
                  {tnl('labels.filters')}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div
                  style={{
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    maxHeight: '85vh'
                  }}
                >
                  <Form
                    type="offcanvas"
                    inputs={inputsToRender}
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={formSubmit}
                  />
                </div>
              </Offcanvas.Body>
            </div>
          </Offcanvas>
        </>
      )}
    </>
  );
};

export default OffCanvasForm;
