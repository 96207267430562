//* External
import PropTypes from 'prop-types';
import { useState } from 'react';

//* App Custom
import { BasicModal, DataMapper } from 'components/common/index';
import { tnl } from 'i18n/i18n';
import { verifyData } from 'utils/cleaners';
import './MoreInfoModal.scss';

const MoreInfoModal = ({
  title = 'components.more_info_modal_title',
  text,
  labelData,
  maxString = 25,
  size = 'lg'
}) => {
  const [showMore, setShowMore] = useState(false);
  const RenderModal = (
    <>
      {showMore && (
        <BasicModal
          title={title}
          body={
            <>
              {labelData ? (
                <DataMapper className="col-12" data={labelData} />
              ) : (
                text
              )}
            </>
          }
          onHide={() => setShowMore(false)}
          size={size}
        />
      )}
      <p className="moreInfoModal py-2">
        {`${text?.substr(0, maxString)}... `}
        <span onClick={() => setShowMore(true)}>
          {tnl('components.more_info_modal_btn')}
        </span>
      </p>
    </>
  );
  return <>{text?.length > maxString ? RenderModal : verifyData([text])}</>;
};

MoreInfoModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  labelData: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.node
      ])
    })
  ),
  maxString: PropTypes.number,
  size: PropTypes.oneOf(['md', 'lg', 'xl'])
};

export default MoreInfoModal;
