//* External
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Form, Modal } from 'react-bootstrap';

//* App Custom
import { Button, ButtonsList, Wizard } from 'components/common';

const WizardModal = ({
  currentView = 0,
  steps,
  handleSubmit,
  body = { 0: <></> },
  submits = { 0: () => {} },
  submitBtnText = { 0: 'buttons.manage' },
  submitBtnTitle = { 0: '' },
  submitBtnDisabled = { 0: false },
  extraFooterBtns = { 0: [] },
  onHide = () => {},
  size = 'lg'
}) => {
  const extraButtons = extraFooterBtns[currentView] || [];
  const memoHeaderSteps = useMemo(() => steps, [steps]);

  return (
    <Modal
      show={true}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
    >
      <Form onSubmit={handleSubmit(submits[currentView])}>
        <Modal.Header closeButton>
          <Wizard currentStep={currentView} steps={memoHeaderSteps} />
        </Modal.Header>
        <Modal.Body>{body[currentView]}</Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap justify-content-end gap-2 px-2">
            <ButtonsList data={extraButtons} />
            <Button
              type="submit"
              text={submitBtnText[currentView]}
              title={submitBtnTitle[currentView]}
              disabled={submitBtnDisabled[currentView]}
            />
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

WizardModal.propTypes = {
  currentView: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  body: PropTypes.object.isRequired,
  submits: PropTypes.object.isRequired,
  submitBtnText: PropTypes.object.isRequired,
  submitBtnTitle: PropTypes.object,
  submitBtnDisabled: PropTypes.object,
  extraFooterBtns: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['md', 'lg', 'xl'])
};

export default WizardModal;
