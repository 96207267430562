//* External
import PropTypes from 'prop-types';

//* App Custom
import { tnl } from 'i18n/i18n';
import './Wizard.scss';

const Wizard = ({ currentStep, steps }) => {
  const wizardActives = steps.map((_, i) => currentStep >= i);

  return (
    <div className="w-100 d-flex gap-3 justify-content-center">
      {wizardActives.map((isActive, i) => (
        <div
          key={`wizard_${i}`}
          className={`wizard ${isActive ? 'wizard-active' : 'wizard-deactive'}`}
        >
          {tnl(steps[i])}
        </div>
      ))}
    </div>
  );
};

Wizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Wizard;
