//* External
import PropTypes from 'prop-types';

//* App Custom
import { CSS_COLORS } from 'constants';
import { tnl } from 'i18n/i18n';

const Indicator = ({ color, label }) => (
  <div className="d-flex align-items-center">
    <div
      style={{
        backgroundColor: CSS_COLORS[color],
        width: 20,
        height: 20,
        borderRadius: 50
      }}
    />
    <p className="ms-2">{tnl(label)}</p>
  </div>
);

Indicator.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'black',
    'gray',
    'gray-light',
    'blue-light',
    'blue-dark',
    'green-light',
    'green-dark',
    'red-light',
    'red-dark',
    'white',
    'yellow-light',
    'yellow-dark',
    'app-primary-color',
    'app-secondary-color',
    'app-tertiary-color',
    'app-disabled-color'
  ]).isRequired
};

export default Indicator;
