//* External
import PropTypes from 'prop-types';

//* App Custom
import { CSS_COLORS, ICONS, ICON_SIZES } from 'constants/index';

const CustomIcon = ({
  name = 'question',
  size = 'S',
  color = 'black',
  className
}) => {
  const IconComponent = ICONS[name] || ICONS['circle'];

  return (
    <IconComponent
      className={className}
      size={ICON_SIZES[size]}
      color={CSS_COLORS[color]}
    />
  );
};

CustomIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(['XS', 'S', 'MD', 'LG', 'XL']),
  color: PropTypes.oneOf([
    'black',
    'gray',
    'gray-light',
    'blue-light',
    'blue-dark',
    'green-light',
    'green-dark',
    'red-light',
    'red-dark',
    'white',
    'yellow-light',
    'yellow-dark',
    'app-primary-color',
    'app-secondary-color',
    'app-tertiary-color',
    'app-disabled-color'
  ]),
  className: PropTypes.string
};

export default CustomIcon;
