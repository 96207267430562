//* External
import { Link } from 'react-router-dom';

//* App Custom
import { CustomIcon } from 'components/common';
import { tnl } from 'i18n/i18n';
import './moduleWrapper.scss';

const ModuleWrapper = ({ data, children }) => {
  const { title, breadcrumbs } = data;

  return (
    <div className="moduleWrapper">
      <div className="moduleWrapper_titleContainer">
        <h2 className="title">{tnl(title)}</h2>
        {breadcrumbs && (
          <div className="d-flex gap-1">
            {breadcrumbs.map((breadcrumb, index) => (
              <div
                className="d-flex align-items-center"
                key={`breadcrumb_${index}`}
              >
                <Link to={breadcrumb.route} state={{}}>
                  {tnl(breadcrumb.label)}
                </Link>
                <CustomIcon name="chevronRight" color="gray" />
              </div>
            ))}
            <p>{tnl(title)}</p>
          </div>
        )}
      </div>
      <div className="moduleWrapper_childrenContainer">{children}</div>
    </div>
  );
};

export default ModuleWrapper;
