//* External
import PropTypes from 'prop-types';
import { Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

//* App Custom
import { Button, ButtonsList, InputHandler } from 'components/common/index';
import { tnl } from 'i18n/i18n';
import '../modals.scss';

const FormModal = ({
  title,
  titleParams = {},
  inputs = () => [],
  submitFn = () => {},
  onHide = () => {},
  params,
  isDoingRequest = false,
  extraButtons = [],
  size = 'lg',
  fullscreen = false
}) => {
  const { control, formState, handleSubmit, ...rest } = useForm({
    mode: 'all'
  });

  return (
    <Modal
      show
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
      fullscreen={fullscreen}
      scrollable
    >
      <Modal.Header closeButton>
        <h4>{tnl(title, titleParams)}</h4>
      </Modal.Header>
      <form onSubmit={handleSubmit(submitFn)} style={{ overflowY: 'auto' }}>
        <Modal.Body>
          <div className="d-flex flex-column gap-2 px-3">
            <Row>
              {inputs({ ...params, ...rest })
                .filter((input) => !input?.isHidden)
                .map((input, index) => (
                  <InputHandler
                    key={input?.key || index}
                    {...input}
                    control={control}
                    disabled={input?.disabled}
                  />
                ))}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            text="btns.close"
            onClick={onHide}
            disabled={isDoingRequest}
          />
          <ButtonsList data={extraButtons} />
          <Button
            type="submit"
            text="btns.submit"
            title={!formState.isValid ? 'titles.missing_required_inputs' : ''}
            disabled={!formState.isValid}
            isDoingRequest={isDoingRequest}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

FormModal.propTypes = {
  title: PropTypes.string.isRequired,
  inputs: PropTypes.func.isRequired,
  submitFn: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  params: PropTypes.object,
  isDoingRequest: PropTypes.bool,
  extraButtons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['button', 'submit']),
      variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'danger']),
      border: PropTypes.oneOf(['full', 'left', 'right', 'none']),
      title: PropTypes.string,
      text: PropTypes.string,
      iconName: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      isDoingRequest: PropTypes.bool,
      fileToDownload: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  size: PropTypes.oneOf(['md', 'lg', 'xl']),
  fullscreen: PropTypes.bool
};

export default FormModal;
