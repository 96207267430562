//* External
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Modal } from 'react-bootstrap';

//* App Custom
import { Button, ButtonsList } from 'components/common';
import { tnl } from 'i18n/i18n';
import '../modals.scss';

const BasicModal = ({
  title,
  titleParams = {},
  body,
  extraButtons = [],
  onHide = () => {},
  size = 'lg',
  fullscreen
}) => {
  return (
    <Modal
      show
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={size}
      fullscreen={fullscreen}
      scrollable
    >
      <Modal.Header closeButton>
        <h4>{tnl(title, titleParams)}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2 px-3">{body}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" text="btns.close" onClick={onHide} />
        <ButtonsList data={extraButtons} />
      </Modal.Footer>
    </Modal>
  );
};

BasicModal.propTypes = {
  title: PropTypes.string.isRequired,
  titleParams: PropTypes.object,
  body: PropTypes.node.isRequired,
  extraButtons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['button', 'submit']),
      variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'danger']),
      border: PropTypes.oneOf(['full', 'left', 'right', 'none']),
      title: PropTypes.string,
      text: PropTypes.string,
      iconName: PropTypes.string,
      disabled: PropTypes.bool,
      fullWidth: PropTypes.bool,
      isDoingRequest: PropTypes.bool,
      fileToDownload: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  onHide: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['md', 'lg', 'xl']),
  fullscreen: PropTypes.bool
};

const MemoizeModal = memo(BasicModal);
export default MemoizeModal;
