//* External
import PropTypes from 'prop-types';

//* App Custom
import { CustomIcon } from 'components/common';
import { tnl } from 'i18n/i18n';
import InputLabel from '../InputLabel/InputLabel';
import '../inputs.scss';

const InputTextArea = ({
  name,
  label,
  placeholder,
  iconName,
  defaultValue,
  disabled = false,
  required = false,
  design = 'col-12',
  hookError,
  hookOnChange = () => {},
  onInputChange = () => {}
}) => {
  return (
    <div className={design}>
      <div
        className={`customInput customInput_textArea ${
          disabled ? 'customInput_disabled' : ''
        }`}
      >
        {label && <InputLabel name={name} text={label} required={required} />}
        <div className="customInput_container">
          {iconName && (
            <CustomIcon
              className="customInput_icon"
              name={iconName}
              color="gray"
            />
          )}
          <textarea
            rows={4}
            id={name}
            placeholder={tnl(placeholder)}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={(e) => {
              const value = e.target.value;
              hookOnChange(value);
              onInputChange(value);
            }}
          />
        </div>
        {hookError?.message && (
          <p className="customInput_error">{tnl(hookError?.message)}</p>
        )}
      </div>
    </div>
  );
};

InputTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  iconName: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  design: PropTypes.string,
  hookError: PropTypes.shape({
    message: PropTypes.string
  }),
  hookOnChange: PropTypes.func,
  onInputChange: PropTypes.func
};

export default InputTextArea;
