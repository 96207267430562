//* External
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

//* App Custom
import { ErrorBoundaryFront } from 'components/common/index';
import { store } from 'redux';
import App from './App.jsx';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ErrorBoundaryFront>
      <App />
    </ErrorBoundaryFront>
  </Provider>
);
