//* External
import PropTypes from 'prop-types';
import { memo, useState } from 'react';

//* App Custom
import { Button } from 'components/common/index';
import { tnl } from 'i18n/i18n';
import InputLabel from '../InputLabel/InputLabel';
import '../inputs.scss';

const InputCheckbox = ({
  name,
  label,
  placeholder,
  disabled = false,
  required = false,
  defaultValue = false,
  design = 'col-12',
  hookError,
  link,
  onInputChange = () => {},
  hookOnChange = () => {}
}) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <div className={design}>
      <div className={`customInput ${disabled ? 'customInput_disabled' : ''}`}>
        {label && <InputLabel name={name} text={label} required={required} />}
        <div className="customInput_checkboxContainer">
          <input
            type="checkbox"
            id={name}
            disabled={disabled}
            defaultChecked={defaultValue}
            onChange={() => {
              setValue(!value);
              hookOnChange(!value);
              onInputChange(!value);
            }}
          />
          {tnl(placeholder)}
          {link && (
            <div className="ps-2">
              <Button variant="link" fileToDownload={link} text="btns.see" />
            </div>
          )}
        </div>
        {hookError?.message && (
          <p className="customInput_error">{tnl(hookError?.message)}</p>
        )}
      </div>
    </div>
  );
};

InputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.bool,
  design: PropTypes.string,
  hookError: PropTypes.shape({
    message: PropTypes.string
  }),
  link: PropTypes.string,
  onInputChange: PropTypes.func,
  hookOnChange: PropTypes.func
};

const MemoizeInputCheckbox = memo(InputCheckbox);
export default MemoizeInputCheckbox;
