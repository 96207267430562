//* External
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

//* App Custom
import { Indicator } from 'components/common';
import { CSS_COLORS } from 'constants';

const Percentage = ({
  value = 0,
  title,
  freeText,
  usedText,
  freeColor = 'green-light',
  usedColor = 'blue-dark',
  size = 110
}) => (
  <div className="border rounded" style={{ width: 'fit-content' }}>
    <div className="px-4 py-3 d-flex flex-column align-items-center">
      <p className="fw-bold mb-2">{title}</p>
      <div className="d-flex align-items-center gap-3">
        <div data-testid="percentage_id" style={{ width: size, height: size }}>
          <CircularProgressbar
            value={value}
            minValue={0}
            maxValue={100}
            text={`${value}%`}
            strokeWidth={7}
            styles={buildStyles({
              pathColor: CSS_COLORS[freeColor],
              trailColor: CSS_COLORS[usedColor],
              textColor: 'var(--black)'
            })}
          />
        </div>
        <div className="d-flex flex-column gap-2 mt-2">
          <Indicator color={usedColor} label={freeText} />
          <Indicator color={freeColor} label={usedText} />
        </div>
      </div>
    </div>
  </div>
);

Percentage.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string,
  freeText: PropTypes.string.isRequired,
  usedText: PropTypes.string.isRequired,
  freeColor: PropTypes.string.isRequired,
  usedColor: PropTypes.string.isRequired,
  size: PropTypes.number
};

export default Percentage;
