//* External
import PropTypes from 'prop-types';
import { useState } from 'react';
import Select from 'react-select';

//* App Custom
import { CustomIcon } from 'components/common';
import { tnl } from 'i18n/i18n';
import InputLabel from '../InputLabel/InputLabel';
import '../inputs.scss';
import './inputSelects.scss';

const InputSelect = ({
  name,
  label,
  placeholder,
  iconName,
  iconColor,
  defaultValue,
  options = [],
  isMulti = false,
  disabled = false,
  required = false,
  design = 'col-12',
  hookError,
  hookOnChange = () => {},
  onInputChange = () => {},
  onEventChange = () => {}
}) => {
  const [resetInput, setResetInput] = useState(0);
  const optionsTranslated = options.map((option) => ({
    label: tnl(option.label),
    value: option.value
  }));

  return (
    <div
      className={`customInput ${design} ${
        disabled ? 'customInput_disabled' : ''
      }`}
    >
      {label && <InputLabel name={name} text={label} required={required} />}
      <div className="customInput_container">
        {iconName && (
          <CustomIcon
            className="customInput_icon"
            name={iconName}
            color={iconColor || 'gray'}
          />
        )}
        <div className="w-100">
          <Select
            key={resetInput}
            inputId={name}
            menuPosition="fixed"
            classNamePrefix="input_select"
            options={optionsTranslated}
            placeholder={tnl(placeholder)}
            defaultValue={defaultValue}
            noOptionsMessage={() => tnl('form_errors.no_options_available')}
            isMulti={isMulti}
            onChange={(data) => {
              let inputValue;
              if (Array.isArray(data)) {
                let hasObject = false;
                inputValue = data.map((item) => {
                  if (item.value?.label) hasObject = true;
                  return item.value;
                });
                if (hasObject) inputValue = data.map((item) => item.value);
                else inputValue = data.map((item) => item.value).join(',');
                hookOnChange(inputValue);
                onEventChange(data);
              } else {
                const { value } = data;
                hookOnChange(value);
                onEventChange(data);
                if (value === '') setResetInput(resetInput + 1);
              }
            }}
            onInputChange={(value) => value && onInputChange(value)}
            isDisabled={disabled}
          />
        </div>
      </div>
      {hookError?.message && (
        <p className="customInput_error">{tnl(hookError?.message)}</p>
      )}
    </div>
  );
};

InputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
      ])
    })
  ]),
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
      ])
    })
  ),
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  design: PropTypes.string,
  hookError: PropTypes.shape({
    message: PropTypes.string
  }),
  hookOnChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onEventChange: PropTypes.func
};

export default InputSelect;
