//* External
import moment from 'moment-timezone';

//* App Custom
import { DATE_FORMATS } from 'constants/index';

export const formatDate = (date, format = DATE_FORMATS.DAY) => {
  const formatDate = new Date(date);
  const isValidDate = !isNaN(formatDate.getTime());
  let dateToReturn = null;

  if (date && isValidDate) {
    dateToReturn = moment(date)
      .tz(import.meta.env.VITE_TIME_ZONE)
      .format(format);
  }
  return dateToReturn;
};

export const formatDateForInput = (date, format = DATE_FORMATS.DAY) => {
  const formatDate = new Date(date);
  const isValidDate = !isNaN(formatDate.getTime());
  let dateToReturn = null;

  if (date && isValidDate) {
    dateToReturn = moment(formatDate)
      .tz(import.meta.env.VITE_TIME_ZONE)
      .format();
    if (format === DATE_FORMATS.DAY) {
      const [day] = dateToReturn.split('T');
      dateToReturn = day;
    } else if (format === DATE_FORMATS.FULL_DATE) {
      dateToReturn = dateToReturn.slice(0, -9);
    }
  }
  return dateToReturn;
};

export const formatDateWithOffset = (date) => {
  const timeOffset = new Date().getTimezoneOffset();
  date.setMinutes(date.getMinutes() + timeOffset);
  const newDate = formatDateForInput(date, DATE_FORMATS.FULL_DATE);
  return newDate;
};
