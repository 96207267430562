import { isArray, pickBy } from 'lodash';

const isAllDataNull = (arrayData = []) => {
  return arrayData.every(
    (data) => data === null || data === undefined || data === ''
  );
};

export const verifyData = (arrayData, separator = ' ') => {
  if (isAllDataNull(arrayData)) return ' - ';
  else return arrayData.join(separator);
};

export const cleanObject = (data = []) =>
  pickBy(data, (value) => {
    if (isArray(value) && value?.length !== 0) return true;
    if (!isArray(value) && !!value) return true;
    return false;
  });

export const cleanArray = (data = []) => data.filter((item) => item != null);

export const cleanArrayOfObjects = (data = []) =>
  data?.filter((obj) => Object.values(obj).every((item) => !!item));
