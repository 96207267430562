//* External
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//* App Custom
import { ErrorBoundaryFront } from 'components/common/index';
import { ROUTES } from 'constants/index';
import { useLocalStorage } from 'hooks/index';
import ModuleWrapper from './ModuleWrapper/ModuleWrapper';

const PrivateRoute = ({ module, children }) => {
  const {
    userData,
    recaptcha,
    navigationErrors,
    removeRecaptcha,
    removeNavigationErrors
  } = useLocalStorage();

  useEffect(() => {
    if (recaptcha) removeRecaptcha();
    else if (navigationErrors.length > 0) {
      if (userData.is_superuser) {
        navigationErrors.map((error) => toast.error(error));
      }
      removeNavigationErrors();
    }
  }, []);

  return (
    <>
      {userData ? (
        <ErrorBoundaryFront>
          <ModuleWrapper data={module}>{children}</ModuleWrapper>
        </ErrorBoundaryFront>
      ) : (
        <Navigate to={ROUTES.ROOT} />
      )}
    </>
  );
};

export default PrivateRoute;
