//* External
import PropTypes from 'prop-types';

//* App Custom
import { tnl } from 'i18n/i18n';

const InputLabel = ({ name, text, required }) => (
  <label htmlFor={name} className="fw-bold">
    {tnl(text)}
    {required && <span className="customInput_required ms-1 text-wrap">*</span>}
  </label>
);

InputLabel.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  required: PropTypes.bool
};

export default InputLabel;
