//* External
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';

//* App Custom
import { NoDataAvailable } from 'components/common';
import { tnl } from 'i18n/i18n';
import './loaderWrapper.scss';

const LoaderWrapper = ({
  isSearching = false,
  data = [],
  message = 'components.loader_wrapper_default_message',
  children
}) => {
  const typeValidation = useMemo(() => {
    if (Array.isArray(data)) return data.length > 0;
    else return Object.keys(data).length > 0;
  }, [data]);

  return (
    <>
      {isSearching ? (
        <div className="my-1 d-flex flex-column align-items-center justify-content-center">
          <Spinner
            role="loading_spinner"
            animation="border"
            className="loader_wrapper_spinner"
          />
          <p className="mt-2 text-wrap">{tnl(message)}</p>
        </div>
      ) : (
        <>{typeValidation ? children : <NoDataAvailable />}</>
      )}
    </>
  );
};

LoaderWrapper.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  message: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default LoaderWrapper;
